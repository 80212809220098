import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { v4 as uuid } from "uuid";
import { BlogPost, SilentAny } from "../utils/types";
import Layout from "../components/Layout";
import { SEO } from "../components/SEO";
import { BlogPostPreview } from "../components/BlogPostPreview";
import { PageOuter } from "../components/PageOuter";
import Spacer from "../components/Spacer";
import { BlogPostCategoryCards } from "../components/CategoryCards";
import PageIntro from "../components/PageIntro";

const Blog = ({
  pageContext,
  location,
}: {
  pageContext: SilentAny;
  location: LocationState;
}): JSX.Element => {
  useEffect(() => {
    Aos.init({
      // can pass aos global animations here
      // alternatively, pass attribute to element directly e.g. data-aos-duration="2000"
      duration: 1500,
      once: true,
      mirror: false,
    });
  }, []);

  const { blogPosts, blogPostsTotalCount, blogPostsByCategory } = pageContext;

  const title = "Blog";
  return (
    <Layout>
      <SEO title={title} />
      <PageOuter title={title} midLayout>
        <PageIntro
          title="Thoughts &#38; Insights"
          intro="As leaders in our industry, we want to share our insights into all things digital. We add new posts regularly, so be sure to check back in to keep up to date."
        />
        <div data-aos="fade" data-aos-delay="250">
          <BlogPostCategoryCards
            pathname={location.pathname}
            blogPostsByCategory={blogPostsByCategory}
            blogPostsTotalCount={blogPostsTotalCount}
          />
          {blogPosts.length > 0 && (
            <>
              <Spacer height={4} />
              {blogPosts.map(
                (
                  blogPost: BlogPost
                  // , index: number
                ) => {
                  // const lastItem =
                  //   index === data.allStrapiBlogPosts.edges.length - 1;
                  return (
                    <div style={{ listStyleType: "none" }} key={uuid()}>
                      <BlogPostPreview item={blogPost} />
                      {/* {!lastItem && <Spacer height={3} />} */}
                    </div>
                  );
                }
              )}
            </>
          )}
        </div>
      </PageOuter>
    </Layout>
  );
};

export default Blog;
