import React from "react";
import Img from "gatsby-image";
import Reactmarkdown from "react-markdown";
import moment from "moment";
import styled from "styled-components";
import { BlogPost, BlogPostCategoryName, FluidImage } from "../utils/types";
import { convertStringToSlug } from "../utils/functions";
import FootnoteText from "./FootnoteText";
import Spacer from "./Spacer";
import { Link } from "gatsby";
import { AuthorProfile } from "./AuthorProfile";
import { BlogPostCategoryCard } from "./CategoryCards";

export const BlogPostPreview = ({
  item,
  category,
}: {
  item: BlogPost;
  category?: BlogPostCategoryName;
}): JSX.Element => {
  const {
    published_at,
    title_image,
    author,
    title,
    body,
    blog_post_category,
    read_time_in_minutes,
  } = item;

  // const pulbicationMonth = moment(published_at).format("MM");
  // const pulbicationYear = moment(published_at).format("YYYY");

  const blogPostSlug = convertStringToSlug(title);

  const categoryName = category
    ? category
    : blog_post_category && blog_post_category.blog_post_category_name
    ? blog_post_category.blog_post_category_name
    : "";

  return (
    <BlogPostStyled>
      {/* <Link to={`/blog/${pulbicationYear}/${pulbicationMonth}/${slug}`}> */}
      <Link
        to={`/blog/${categoryName.toLowerCase()}/${blogPostSlug}`}
        tabIndex={0}
        aria-label={`Blog post title: ${title}`}
      >
        <BlogPostPreviewContainer>
          <DetailsContainer>
            <AuthorProfile author={author} />
            <Spacer />
            <TitlePreview>{title}</TitlePreview>
            <BodyPreview>{body}</BodyPreview>
            <Spacer />
            {/* <Spacer
          style={{ backgroundColor: theme.colours.foreground }}
          unit="px"
        />
        <Spacer /> */}
            <FootnoteText>
              <div style={{ display: "flex" }}>
                <BlogPostCategoryCard categoryName={categoryName} />
                &nbsp;
                {`· ${read_time_in_minutes} min read · ${moment(
                  published_at
                ).format("LL")}`}
              </div>
            </FootnoteText>
          </DetailsContainer>
          {title_image && (
            <>
              <ImageSmallStyled
                alt=""
                fluid={title_image.childImageSharp.fluid}
              />
            </>
          )}
        </BlogPostPreviewContainer>
      </Link>
    </BlogPostStyled>
  );
};
const BlogPostStyled = styled.div`
  margin-bottom: 4rem;
  a:focus-visible {
    > div {
      box-shadow: 0 0 0 2px ${(props) => props.theme.colours.midBlue};
    }
    h3 {
      color: ${(props) => props.theme.colours.midBlue};
    }
  }
`;
const BlogPostPreviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: normal;
  color: ${(props) => props.theme.colours.foregroundSecondary};
  text-decoration: none;
  h3 {
    color: ${(props) => props.theme.colours.foregroundPrimary};
    transition: all 0.2s ease-in;
  }
  p {
    margin-bottom: 0;
  }
  &:hover {
    cursor: pointer;
    h3 {
      color: ${(props) => props.theme.colours.midBlue};
    }
    // .gatsby-image-wrapper:after {
    //   background-position: -20% 0; /* this works in conjunction with the &"after on ImageSmallStyled to make the shine animation */
    //   transition: 0.7s; /* put transition here rather that on ImageSmallStyled &:after to stop shine animation reverse on mouseout */
    // }
  }
  @media (max-width: ${(props) => props.theme.screenSize.m}px) {
    flex-direction: column-reverse;
  }
`;
const DetailsContainer = styled.div`
  flex: 1;
  padding-right: 2rem;
  @media (max-width: ${(props) => props.theme.screenSize.m}px) {
    padding-right: 0;
  }
`;
const BodyPreview = styled(Reactmarkdown)`
  color: ${(props) => props.theme.colours.midGrey};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  height: 2.2rem; /* .2rem allows for low hangin letters like 'g' or 'y'. */
  a {
    visibility: hidden; /* this stops any links in the post body from being tabable or visible to screen readers in the preview */
  }
  /* Shadow text fade out effect alternative to ellipsis which works well on safari, which can have issues with ellipsis and line-clamp */
  // position: relative;
  // height: 2.1rem; /* two lines */
  // &:after {
  //   content: "";
  //   text-align: right;
  //   position: absolute;
  //   bottom: 0;
  //   right: 0;
  //   width: 20%;
  //   height: 1.2rem;
  //   background: linear-gradient(
  //     to right,
  //     rgba(255, 255, 255, 0),
  //     rgba(255, 255, 255, 1) 50%
  //   );
  // }
`;
const TitlePreview = styled.h3`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
`;
// passing fluid prop to styled component here to stop tsc warning
const ImageSmallStyled = styled(Img)<{ fluid: FluidImage }>`
  width: 100%;
  height: 308px; /* keep aspect ratio of 1.3/1 when possible without using css property aspect-ration which is unsupported by Safari browser */
  max-width: 400px;
  border-radius: ${(props) => props.theme.borderRadius.card};
  &:before,
  &:after {
    border-radius: ${(props) => props.theme.borderRadius.card};
    background-position-y: center !important; // override gatsby-image default of bottom to keep fluid image centered when changing screen size and/or image dimensions
  }
  @media (max-width: ${(props) => props.theme.screenSize.m}px) {
    margin: 0 auto 2rem auto;
  }
  // &:after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: linear-gradient(
  //     45deg,
  //     transparent 50%,
  //     rgba(255, 255, 255, 0.03) 58%,
  //     rgba(255, 255, 255, 0.16) 67%,
  //     transparent 68%
  //   );
  //   background-size: 200% 100%;
  //   background-position: 175% 0;
  // }
`;
